import logo from "./assets/axraLogo.png";
import dashboard from "./assets/dashboard.png";
import textLogo from "./assets/logo.png";
import service from "./assets/devices.png";
import home from "./assets/home_app_logo.png";
import product from "./assets/laptop_mac.png";

export default {
  logo,
  service,
  home,
  textLogo,
  product,
};
