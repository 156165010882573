const MonthlyCalendar = () => {
  return (
    <>
      <div className="bg-white p-5 rounded-lg shadow-lg w-1/2 h-72">
        <h1>Weekly Appointment </h1>
      </div>
    </>
  );
};

export default MonthlyCalendar;
